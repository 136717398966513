import { Title, Text, Button, Center, Card, Group, Badge, useMantineTheme } from '@mantine/core'
import { Component, ReactNode } from 'react'

interface ErrorBoundaryProps {
  children?: ReactNode | ReactNode[]
}

interface ErrorBoundaryState {
  hasError: boolean
}

export default class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true }
  }

  render() {
    return this.state.hasError ? <ErrorFallback /> : this.props.children
  }
}

function ErrorFallback() {
  const theme = useMantineTheme()

  const secondaryColor = theme.colorScheme === 'dark'
    ? theme.colors.dark[1]
    : theme.colors.gray[7]

  return (
    <Center style={{ width: '100%', height: '100%' }}>
      <div style={{ width: 400, margin: 'auto' }}>
        <Card shadow="lg" p="lg">
          <Group position="apart" style={{ marginBottom: 5 }}>
            <Title order={2}>Houston, we have a problem.</Title>
          </Group>

          <Text style={{ color: secondaryColor, lineHeight: 1.5 }}>
            Something has gone seriously wrong. <br />
            Please try reloading the page.
          </Text>

          <Button variant="light" color="blue" fullWidth style={{ marginTop: 14 }} onClick={() => window.location.reload()}>
            Reload
          </Button>
        </Card>
      </div>
    </Center>
  )
}
