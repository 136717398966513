import React from 'react'
import { GitPullRequest, AlertCircle, Messages, Database } from 'tabler-icons-react'
import { ThemeIcon, UnstyledButton, Group, Text, Anchor } from '@mantine/core'
import { Link } from 'react-router-dom'

interface NavItemProps {
  icon: React.ReactNode
  color: string
  label: string
  href: string
}

function NavItem({ icon, color, label, href }: NavItemProps) {
  return (
    <Anchor component={Link} to={href}>
      <UnstyledButton
        sx={(theme) => ({
          display: 'block',
          width: '100%',
          padding: theme.spacing.xs,
          borderRadius: theme.radius.sm,
          color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

          '&:hover': {
            backgroundColor:
              theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
          },
        })}
      >
        <Group>
          <ThemeIcon color={color} variant="light">
            {icon}
          </ThemeIcon>

          <Text size="sm">{label}</Text>
        </Group>
      </UnstyledButton>
    </Anchor>
  )
}

const data = [
  { icon: <GitPullRequest size={16} />, color: 'blue', label: 'Pull Requests', href: '/pull-requests' },
  { icon: <AlertCircle size={16} />, color: 'teal', label: 'Open Issues', href: '/open-issues' },
  { icon: <Messages size={16} />, color: 'violet', label: 'Discussions', href: '/discussions' },
  { icon: <Database size={16} />, color: 'grape', label: 'Databases', href: '/databases' },
]

export function NavItems() {
  const links = data.map((link) => <NavItem {...link} key={link.label} />)
  return <div>{links}</div>
}