import React, { useState } from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import {
  AppShell,
  Navbar,
  Header,
  Text,
  MediaQuery,
  Burger,
  useMantineTheme,
  Title,
  ThemeIcon,
  Group,
} from '@mantine/core'
import { Satellite } from 'tabler-icons-react'
import './App.css'
import ErrorBoundary from './components/ErrorBoundary'
import Home from './features/home/Home'
import Test from './features/test/Test'
import AnotherTest from './features/anothertest/AnotherTest'
import { NavItems } from './components/core/NavItems'

function Frame() {
  return (
    <Router>
      <FrameRoutes />
    </Router>
  )
}

function FrameRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/test" element={<Test />} />
      <Route path="/anothertest" element={<AnotherTest />} />
    </Routes>
  )
}

export default function App() {
  const theme = useMantineTheme()
  const [opened, setOpened] = useState(false)
  return (
    <ErrorBoundary>
      <AppShell
        styles={{
          main: {
            background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
          },
        }}
        navbarOffsetBreakpoint="sm"
        asideOffsetBreakpoint="sm"
        fixed
        navbar={
          <Navbar p="md" hiddenBreakpoint="sm" hidden={!opened} width={{ sm: 200, lg: 300 }}>
            <Navbar.Section grow mt="md">
              <NavItems />
            </Navbar.Section>
            <Navbar.Section>
              
            </Navbar.Section>
          </Navbar>
        }
        header={
          <Header height={70} p="md">
            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
              <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
                <Burger
                  opened={opened}
                  onClick={() => setOpened((o) => !o)}
                  size="sm"
                  color={theme.colors.gray[6]}
                  mr="xl"
                />
              </MediaQuery>
              <Group>
                <ThemeIcon variant="light" radius="md" size="xl" color="dark">
                  <Satellite />
                </ThemeIcon>
                <Title order={2}>SatTracker</Title>
              </Group>
            </div>
          </Header>
        }
      >
        <Frame />
      </AppShell>
    </ErrorBoundary>
  )
}
